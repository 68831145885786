import _findIndexInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find-index";

function getFirstItem(sortedItems) {
  return sortedItems[0];
}

function getLastItem(sortedItems) {
  return sortedItems[sortedItems.length - 1];
}

function getNextItem(sortedItems, activeItem) {
  var focusedIndex = _findIndexInstanceProperty(sortedItems).call(sortedItems, function (el) {
    return el === activeItem;
  });

  var indexToFocus = focusedIndex + 1;

  if (indexToFocus < sortedItems.length) {
    return sortedItems[indexToFocus];
  } else {
    return getFirstItem(sortedItems);
  }
}

function getPrevItem(sortedItems, activeItem) {
  var focusedIndex = _findIndexInstanceProperty(sortedItems).call(sortedItems, function (el) {
    return el === activeItem;
  });

  var indexToFocus = focusedIndex - 1;

  if (indexToFocus >= 0) {
    return sortedItems[indexToFocus];
  } else {
    return getLastItem(sortedItems);
  }
}

function htmlElementsComparator(a, b) {
  if (a.offsetTop > b.offsetTop) {
    return 1;
  }

  if (a.offsetTop < b.offsetTop) {
    return -1;
  }

  if (a.offsetLeft > b.offsetLeft) {
    return 1;
  }

  if (a.offsetLeft < b.offsetLeft) {
    return -1;
  }

  return 0;
}

export { getFirstItem, getLastItem, getNextItem, getPrevItem, htmlElementsComparator };
