function getMaxWidth(dropdownMaxWidth, referenceWidth) {
  if (dropdownMaxWidth === 'trigger') {
    return "".concat(referenceWidth, "px");
  } else if (typeof dropdownMaxWidth === 'number') {
    return "".concat(dropdownMaxWidth, "px");
  } else if (dropdownMaxWidth) {
    return dropdownMaxWidth;
  }

  return undefined; //rely on CSS by default
}

function getMinWidth(dropdownMinWidth, referenceWidth) {
  if (dropdownMinWidth === 'trigger') {
    return "".concat(referenceWidth, "px");
  }

  if (typeof dropdownMinWidth === 'number') {
    return "".concat(dropdownMinWidth, "px");
  } else if (dropdownMinWidth) {
    return dropdownMinWidth;
  }

  return undefined; //rely on CSS by default
}

var sameWidthModifier = {
  name: 'sameWidth',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn: function fn(_ref) {
    var state = _ref.state,
        _ref$options = _ref.options;
    _ref$options = _ref$options === void 0 ? {} : _ref$options;
    var maxWidth = _ref$options.maxWidth,
        minWidth = _ref$options.minWidth;
    state.styles.popper.minWidth = getMinWidth(minWidth, state.rects.reference.width);
    state.styles.popper.maxWidth = getMaxWidth(maxWidth, state.rects.reference.width);
  },
  effect: function effect(_ref2) {
    var state = _ref2.state,
        _ref2$options = _ref2.options;
    _ref2$options = _ref2$options === void 0 ? {} : _ref2$options;
    var maxWidth = _ref2$options.maxWidth,
        minWidth = _ref2$options.minWidth;
    state.elements.popper.style.minWidth = getMinWidth(minWidth, state.elements.reference.offsetWidth);
    state.elements.popper.style.maxWidth = getMaxWidth(maxWidth, state.elements.reference.offsetWidth);
    return undefined;
  }
};
var applyMaxSize = {
  name: 'applyMaxSize',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['maxSize'],
  // eslint-disable-next-line complexity
  fn: function fn(_ref3) {
    var state = _ref3.state,
        _ref3$options = _ref3.options,
        _ref3$options$leastMa = _ref3$options.leastMaxHeight,
        leastMaxHeight = _ref3$options$leastMa === void 0 ? 0 : _ref3$options$leastMa,
        _ref3$options$maxHeig = _ref3$options.maxHeight,
        maxHeight = _ref3$options$maxHeig === void 0 ? Infinity : _ref3$options$maxHeig;
    var height = state.modifiersData.maxSize.height;
    var heightLeft = height;
    var resultHeightString;

    if (maxHeight > heightLeft && heightLeft > leastMaxHeight) {
      resultHeightString = "".concat(heightLeft, "px");

      if (state.styles.popper.maxHeight !== resultHeightString && state.rects.popper.height >= heightLeft) {
        // change popper height for detectOverflow util to force flip modifier to find better placement
        state.rects.popper.height = heightLeft;
        state.reset = true;
      }
    } else if (heightLeft < leastMaxHeight) {
      resultHeightString = "".concat(leastMaxHeight, "px");

      if (state.styles.popper.maxHeight !== resultHeightString && state.rects.popper.height >= leastMaxHeight) {
        // change popper height for detectOverflow util to force flip modifier to find better placement
        state.rects.popper.height = leastMaxHeight;
        state.reset = true;
      }
    } else {
      resultHeightString = "".concat(maxHeight, "px");
    }

    state.styles.popper.maxHeight = resultHeightString;
  }
};
export { applyMaxSize, sameWidthModifier };
