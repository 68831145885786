
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './index.p.css';

const sizeStyles = {
  "xs": styles.sizeXS,"s": styles.sizeS,"m": styles.sizeM,"l": styles.sizeL
}

export const Size = {
  "xs": "xs","s": "s","m": "m","l": "l"
};

const CloseIcon = React.forwardRef(
  ({ theme, size, className, ...props }, ref) => {return (<svg viewBox="0 0 24 24" {...props} className={cn(styles.icon, styles[theme], sizeStyles[size], className)} ref={ref}><path d="M5.67 19.751l6.342-6.34 6.34 6.34 1.41-1.409-6.341-6.34 6.34-6.341-1.408-1.409-6.341 6.341L5.67 4.251 4.26 5.66l6.342 6.342-6.34 6.34 1.408 1.409z" /></svg>)}
);

CloseIcon.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.oneOf(["light", "dark"]),
  size: PropTypes.oneOf(["xs", "s", "m", "l"])
};

CloseIcon.defaultProps = {
  size: "m"
};

export default CloseIcon;