import _slicedToArray from "@babel/runtime-corejs3/helpers/esm/slicedToArray";
import { useState, useRef, useCallback } from 'react';
var DROPDOWN_STATES;

(function (DROPDOWN_STATES) {
  DROPDOWN_STATES["OPENING"] = "OPENING";
  DROPDOWN_STATES["OPEN"] = "OPEN";
  DROPDOWN_STATES["CLOSING"] = "CLOSING";
  DROPDOWN_STATES["CLOSE"] = "CLOSE";
})(DROPDOWN_STATES || (DROPDOWN_STATES = {}));

function useDropdownState(isOpen, cancelCloseAnimation) {
  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      setDummyState = _useState2[1];

  var stateRef = useRef(DROPDOWN_STATES.CLOSE);
  var onAnimationEnd = useCallback(function () {
    if ((stateRef.current === DROPDOWN_STATES.CLOSING || stateRef.current === DROPDOWN_STATES.OPENING) && !isOpen) {
      stateRef.current = DROPDOWN_STATES.CLOSE; //force rerender

      setDummyState(function (s) {
        return !s;
      });
    } else if (stateRef.current === DROPDOWN_STATES.OPENING && isOpen) {
      stateRef.current = DROPDOWN_STATES.OPEN; //force rerender

      setDummyState(function (s) {
        return !s;
      });
    }
  }, [isOpen]);

  if (isOpen && stateRef.current !== DROPDOWN_STATES.OPEN) {
    stateRef.current = DROPDOWN_STATES.OPENING;
  } else if (stateRef.current === DROPDOWN_STATES.OPEN && !isOpen) {
    stateRef.current = cancelCloseAnimation ? DROPDOWN_STATES.CLOSE : DROPDOWN_STATES.CLOSING;
  }

  return [onAnimationEnd, stateRef.current];
}

export { DROPDOWN_STATES, useDropdownState };
