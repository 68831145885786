import PropTypes from 'prop-types';
var optionType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  disabled: PropTypes.bool
});
var optionsGroupType = PropTypes.shape({
  label: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(optionType)
});
var noOptionsMessageConfigType = PropTypes.shape({
  noOptions: PropTypes.string.isRequired,
  nothingFound: PropTypes.string,
  loading: PropTypes.string
});
var noOptionsMessageType = PropTypes.oneOfType([PropTypes.string, noOptionsMessageConfigType]);
export { noOptionsMessageConfigType, noOptionsMessageType, optionType, optionsGroupType };
