
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './index.p.css';

const sizeStyles = {
  "xs": styles.sizeXS,"s": styles.sizeS,"m": styles.sizeM,"l": styles.sizeL
}

export const Size = {
  "xs": "xs","s": "s","m": "m","l": "l"
};

const CheckIcon = React.forwardRef(
  ({ theme, size, className, ...props }, ref) => {return (<svg viewBox="0 0 24 24" {...props} className={cn(styles.icon, styles[theme], sizeStyles[size], className)} ref={ref}><path d="M8 20l-6-5.98 1.42-1.41L8 17.18l12.59-12.6L22 6 8 20z" /></svg>)}
);

CheckIcon.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.oneOf(["light", "dark"]),
  size: PropTypes.oneOf(["xs", "s", "m", "l"])
};

CheckIcon.defaultProps = {
  size: "m"
};

export default CheckIcon;