module.exports = {
  RS_COLOR_DANGER: '#F45C4A',
  RS_COLOR_DANGER_BG: 'rgba(244,92,74, 0.2)',
  RS_COLOR_SUCCESS: '#4DBB5F',
  RS_COLOR_SUCCESS_BG: 'rgba(77,187,95, 0.2)',
  RS_COLOR_WARNING: '#F3C033',
  RS_COLOR_WARNING_BG: 'rgba(243,192,51, 0.2)',
  RS_COLOR_PRIMARY_LIGHT_THEME: '#6B57FF',
  RS_COLOR_PRIMARY_DIM_LIGHT_THEME: '#8979ff',
  RS_COLOR_PRIMARY_FOG_LIGHT_THEME: '#e1ddff',
  RS_COLOR_PRIMARY_T_DIM_LIGHT_THEME: 'rgba(107, 87, 255, 0.8)',
  RS_COLOR_PRIMARY_T_FOG_LIGHT_THEME: 'rgba(107, 87, 255,0.2)',
  RS_COLOR_PRIMARY_DARK_THEME: '#8473FF',
  RS_COLOR_PRIMARY_DIM_DARK_THEME: '#6f61d2',
  RS_COLOR_PRIMARY_FOG_DARK_THEME: '#2e2b49',
  RS_COLOR_PRIMARY_T_DIM_DARK_THEME: 'rgba(132, 115, 255,0.8)',
  RS_COLOR_PRIMARY_T_FOG_DARK_THEME: 'rgba(132, 115, 255,0.3)',
  RS_COLOR_BLACK: '#19191C',
  RS_COLOR_WHITE: '#FFFFFF',
  RS_COLOR_BLACK_T95: 'rgba(25,25,28,0.95)',
  RS_COLOR_BLACK_T90: 'rgba(25,25,28,0.9)',
  RS_COLOR_BLACK_T80: 'rgba(25,25,28,0.8)',
  RS_COLOR_BLACK_T70: 'rgba(25,25,28,0.7)',
  RS_COLOR_BLACK_T60: 'rgba(25,25,28,0.6)',
  RS_COLOR_BLACK_T50: 'rgba(25,25,28,0.5)',
  RS_COLOR_BLACK_T40: 'rgba(25,25,28,0.4)',
  RS_COLOR_BLACK_T30: 'rgba(25,25,28,0.3)',
  RS_COLOR_BLACK_T20: 'rgba(25,25,28,0.2)',
  RS_COLOR_BLACK_T10: 'rgba(25,25,28,0.1)',
  RS_COLOR_BLACK_T5: 'rgba(25,25,28,0.05)',
  RS_COLOR_WHITE_T5: 'rgba(255,255,255,0.05)',
  RS_COLOR_WHITE_T10: 'rgba(255,255,255,0.1)',
  RS_COLOR_WHITE_T20: 'rgba(255,255,255,0.2)',
  RS_COLOR_WHITE_T30: 'rgba(255,255,255,0.3)',
  RS_COLOR_WHITE_T40: 'rgba(255,255,255,0.4)',
  RS_COLOR_WHITE_T50: 'rgba(255,255,255,0.5)',
  RS_COLOR_WHITE_T60: 'rgba(255,255,255,0.6)',
  RS_COLOR_WHITE_T70: 'rgba(255,255,255,0.7)',
  RS_COLOR_WHITE_T80: 'rgba(255,255,255,0.8)',
  RS_COLOR_WHITE_T90: 'rgba(255,255,255,0.9)',
  RS_COLOR_WHITE_T95: 'rgba(255,255,255,0.95)',
  RS_COLOR_GREY_95: '#252528',
  RS_COLOR_GREY_90: '#303033',
  RS_COLOR_GREY_80: '#474749',
  RS_COLOR_GREY_70: '#5E5E60',
  RS_COLOR_GREY_60: '#757577',
  RS_COLOR_GREY_50: '#8C8C8E',
  RS_COLOR_GREY_40: '#A3A3A4',
  RS_COLOR_GREY_30: '#BABABB',
  RS_COLOR_GREY_20: '#D1D1D2',
  RS_COLOR_GREY_10: '#E8E8E8',
  RS_COLOR_GREY_5: '#F4F4F4',
}