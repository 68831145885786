import { useRef, useEffect } from 'react';

var useResizeObserver = function useResizeObserver(target, callback) {
  var cb = useRef();
  cb.current = callback;
  useEffect(function () {
    if (!target) {
      return;
    }

    var targetEl;

    if ('current' in target) {
      targetEl = target.current;
    } else {
      targetEl = target;
    }

    if (!targetEl) {
      return;
    }

    var firstCall = true;
    var abort = false;

    var onResize = function onResize(entries) {
      // on the first call, delay it by a frame, see https://stackoverflow.com/a/58701523
      if (firstCall) {
        firstCall = false;
        window.requestAnimationFrame(function () {
          onResize(entries);
        });
        return;
      }

      if (!Array.isArray(entries) || !entries.length || abort) {
        return;
      }

      if (cb.current && typeof cb.current === 'function') {
        cb.current(entries[0]);
      }
    };

    var resizeObserver = new ResizeObserver(onResize);
    resizeObserver.observe(targetEl); // eslint-disable-next-line consistent-return

    return function () {
      abort = true;
      resizeObserver.disconnect();
    };
  }, [target]);
};

export { useResizeObserver };
