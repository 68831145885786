import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import { useTheme } from '@rescui/ui-contexts';
import bemCnFast from 'bem-cn-fast';
var THEMED_TYPES = ['rs-super-hero', 'rs-digits-1', 'rs-digits-2', 'rs-hero', 'rs-subtitle-1', 'rs-h1', 'rs-subtitle-2', 'rs-h2', 'rs-h3', 'rs-h4', 'rs-h5', 'rs-overline', 'rs-pre', 'rs-code', 'rs-link', 'rs-article', 'rs-text-1', 'rs-text-2', 'rs-text-3', 'rs-link'];

function createTextCn(theme) {
  if (!theme) {
    throw new TypeError('theme argument is required');
  }

  function textCn() {
    var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'rs-text-1';

    var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
        _ref$paragraphOffsetA = _ref.paragraphOffsetAuto,
        paragraphOffsetAuto = _ref$paragraphOffsetA === void 0 ? false : _ref$paragraphOffsetA,
        _ref$hardness = _ref.hardness,
        hardness = _ref$hardness === void 0 ? undefined : _ref$hardness,
        _ref$external = _ref.external,
        external = _ref$external === void 0 ? false : _ref$external,
        _ref$mode = _ref.mode,
        mode = _ref$mode === void 0 ? 'classic' : _ref$mode,
        _ref$alpha = _ref.alpha,
        alpha = _ref$alpha === void 0 ? undefined : _ref$alpha,
        _ref$offsetItems = _ref.offsetItems,
        offsetItems = _ref$offsetItems === void 0 ? undefined : _ref$offsetItems;

    if (type === 'rs-digits') {
      // eslint-disable-next-line no-param-reassign
      type = 'rs-digits-1';
      console.warn("'rs-digits' style is deprecated, please use 'rs-digits-1' instead");
    }

    return bemCnFast(type)({
      'paragraph-offset-auto': paragraphOffsetAuto,
      hardness: hardness,
      external: external,
      mode: type === 'rs-link' && mode,
      theme: _includesInstanceProperty(THEMED_TYPES).call(THEMED_TYPES, type) && theme,
      alpha: alpha,
      'offset-items': offsetItems
    });
  }

  return textCn;
}

var useTextStyles = function useTextStyles() {
  return createTextCn(useTheme());
};

export { createTextCn, useTextStyles };
