import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _slicedToArray from "@babel/runtime-corejs3/helpers/esm/slicedToArray";
import _toConsumableArray from "@babel/runtime-corejs3/helpers/esm/toConsumableArray";
import _defineProperty from "@babel/runtime-corejs3/helpers/esm/defineProperty";
import _findIndexInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find-index";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";

function ownKeys(object, enumerableOnly) { var keys = _Object$keys(object); if (_Object$getOwnPropertySymbols) { var symbols = _Object$getOwnPropertySymbols(object); enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) { return _Object$getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : _Object$getOwnPropertyDescriptors ? Object.defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key)); }); } return target; }

import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { useRef } from 'react';
import { ARROW_DOWN, ARROW_UP, SPACE, ENTER } from './key-codes.js';
var DEFAULT_NO_OPTIONS_MESSAGES = {
  noOptions: 'No options',
  nothingFound: 'Nothing found',
  loading: 'Loading...'
};
var MAX_VISIBLE_ITEMS = 5;
var ITEM_HEIGHT_BY_SIZE = {
  s: 32,
  m: 40,
  l: 52
};

var isOptionsGroup = function isOptionsGroup(optionOrGroup) {
  return typeof optionOrGroup.options !== 'undefined';
};

function shouldOpenSelect(key) {
  var isTriggerTarget = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  return key === ARROW_DOWN || key === ARROW_UP || (key === SPACE || key === ENTER) && isTriggerTarget;
}

function isString(s) {
  return Object.prototype.toString.call(s) === '[object String]';
}

function getEmptyMessage(isLoading, isNothingFound, noOptionsMessage) {
  var _ref = isString(noOptionsMessage) ? _objectSpread(_objectSpread({}, DEFAULT_NO_OPTIONS_MESSAGES), {}, {
    noOptions: noOptionsMessage
  }) : _objectSpread(_objectSpread({}, DEFAULT_NO_OPTIONS_MESSAGES), noOptionsMessage),
      noOptions = _ref.noOptions,
      nothingFound = _ref.nothingFound,
      loading = _ref.loading;

  if (isLoading) {
    return loading;
  } else if (isNothingFound) {
    return nothingFound;
  } else {
    return noOptions;
  }
}

function escapeRegex(str) {
  return str.replace(/[\-\[\]\/{}()\*+?.\\^$|]/g, '\\$&');
}

function convertToOptionGroupsIfNeeded() {
  var optionsOrGroups = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var newOptions = [];
  var topLevelOptions = [];
  optionsOrGroups.forEach(function (optionOrGroup) {
    if (isOptionsGroup(optionOrGroup)) {
      if (topLevelOptions.length) {
        newOptions.push({
          options: topLevelOptions
        });
        topLevelOptions = [];
      }

      newOptions.push(optionOrGroup);
    } else {
      topLevelOptions.push(optionOrGroup);
    }
  });

  if (topLevelOptions.length) {
    newOptions.push({
      options: topLevelOptions
    });
    topLevelOptions = [];
  }

  return newOptions;
}

var calculateMaxHeight = function calculateMaxHeight(size) {
  var itemHeight = ITEM_HEIGHT_BY_SIZE[size]; // half of item added to show that there is more items even if scroll bar hidden

  return (Math.ceil(MAX_VISIBLE_ITEMS) + 0.5) * itemHeight;
};

var reverseTheme = function reverseTheme(theme) {
  return theme === 'dark' ? 'light' : 'dark';
};

function getOptionValue(option) {
  return typeof option.value === 'string' ? option.value : option.label;
}

var OptionGroupTypes = {
  HEADER: 'header',
  OPTION: 'option',
  DIVIDER: 'divider'
};

var getDefaultCursorIndex = function getDefaultCursorIndex(groups) {
  if (groups.length === 0) {
    return null;
  }

  var firstNonEmptyGroupIndex = _findIndexInstanceProperty(groups).call(groups, function (group) {
    return group.options && group.options.length > 0;
  });

  if (firstNonEmptyGroupIndex === -1) {
    return null;
  }

  return [firstNonEmptyGroupIndex, 0];
};

function optionsNotEmpty(options) {
  return options && options.length > 0;
}

var getPlainOptionsFromGroups = function getPlainOptionsFromGroups(groups) {
  return groups.reduce( // eslint-disable-next-line no-shadow
  function (acc, group, i) {
    var _context, _context2, _context3;

    return _concatInstanceProperty(acc).call(acc, _filterInstanceProperty(_context = _concatInstanceProperty(_context2 = [optionsNotEmpty(group.options) && i !== 0 && !group.label && {
      type: OptionGroupTypes.DIVIDER
    }, optionsNotEmpty(group.options) && group.label && {
      type: OptionGroupTypes.HEADER,
      label: group.label
    }]).call(_context2, _toConsumableArray(_mapInstanceProperty(_context3 = group.options).call(_context3, function (option, j) {
      return _objectSpread(_objectSpread({}, option), {}, {
        index: [i, j],
        type: OptionGroupTypes.OPTION
      });
    })))).call(_context, function (e) {
      return e;
    }));
  }, []);
};

function isGroupsEmpty(groups) {
  return groups.length === 0 || groups.every(function (g) {
    return !g.options || g.options.length === 0;
  });
}

function useStrongMemo(callback, deps) {
  var prevDeps = useRef([]);
  var memoResult = useRef(null);

  if (deps.length !== prevDeps.current.length || !prevDeps.current.every(function (dep, i) {
    return dep === deps[i];
  })) {
    prevDeps.current = _toConsumableArray(deps);
    memoResult.current = callback();
  }

  return memoResult.current;
}

function nextIndex(index, isDownDirection) {
  return isDownDirection ? index + 1 : index - 1;
}

function isIndexOutOfRange(index, len) {
  return index < 0 || index >= len;
} // eslint-disable-next-line complexity


function getMovedCursorIndex(groups, currentCursorIndex, isDownDirection) {
  if (isGroupsEmpty(groups)) return currentCursorIndex;

  var _currentCursorIndex = _slicedToArray(currentCursorIndex, 2),
      grpIndex = _currentCursorIndex[0],
      optIndex = _currentCursorIndex[1];

  var optLenByGroup = _mapInstanceProperty(groups).call(groups, function (g) {
    return g.options ? g.options.length : 0;
  });

  var newGrpIndex = grpIndex;
  var newOptIndex = nextIndex(optIndex, isDownDirection);

  while (isIndexOutOfRange(newOptIndex, optLenByGroup[newGrpIndex]) || isIndexOutOfRange(newGrpIndex, groups.length)) {
    newGrpIndex = nextIndex(newGrpIndex, isDownDirection);

    if (isIndexOutOfRange(newGrpIndex, groups.length)) {
      newGrpIndex = isDownDirection ? 0 : groups.length - 1;
    }

    newOptIndex = isDownDirection ? 0 : optLenByGroup[newGrpIndex] - 1;
  }

  return [newGrpIndex, newOptIndex];
}

var isIndexMatchCursor = function isIndexMatchCursor(cursorIndex, index) {
  if (cursorIndex === null) return false;
  return index[0] === cursorIndex[0] && index[1] === cursorIndex[1];
};

var withoutGroupsLabel = function withoutGroupsLabel(groups) {
  return groups.every(function (g) {
    return !g.label;
  });
};

export { OptionGroupTypes, calculateMaxHeight, convertToOptionGroupsIfNeeded, escapeRegex, getDefaultCursorIndex, getEmptyMessage, getMovedCursorIndex, getOptionValue, getPlainOptionsFromGroups, isGroupsEmpty, isIndexMatchCursor, reverseTheme, shouldOpenSelect, useStrongMemo, withoutGroupsLabel };
