import _slicedToArray from "@babel/runtime-corejs3/helpers/esm/slicedToArray";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import { useState, useEffect } from 'react';
import { ResizeObserver as ResizeObserver$1 } from '@juggle/resize-observer';
var ResizeObserver = typeof window !== 'undefined' && window.ResizeObserver || ResizeObserver$1;

function useResizeObserver(nodeRef, onResize) {
  var _context;

  var deps = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];

  // Use resize observer for handling scroll container size changes
  var _useState = useState(null),
      _useState2 = _slicedToArray(_useState, 2),
      resizeObserver = _useState2[0],
      setResizeObserver = _useState2[1]; // use `useEffect()` for skipping ResizeObserver initialization during SSR


  useEffect(function () {
    var newResizeObserver = new ResizeObserver(function () {
      // `requestAnimationFrame` is a fix for error 'ResizeObserver loop completed with undelivered notifications'.
      // read more https://github.com/WICG/resize-observer/issues/38#issuecomment-422126006
      requestAnimationFrame(function () {
        return onResize();
      });
    });
    setResizeObserver(newResizeObserver);
    return function () {
      newResizeObserver.disconnect();
    };
  }, [onResize]);
  useEffect(function () {
    var node = nodeRef.current;

    if (!node || !resizeObserver) {
      return undefined;
    }

    resizeObserver.observe(node);
    return function () {
      resizeObserver.unobserve(node);
    };
  }, _concatInstanceProperty(_context = [resizeObserver]).call(_context, deps));
}

export { useResizeObserver };
