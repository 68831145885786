
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './index.p.css';

const sizeStyles = {
  "xs": styles.sizeXS,"s": styles.sizeS,"m": styles.sizeM,"l": styles.sizeL
}

export const Size = {
  "xs": "xs","s": "s","m": "m","l": "l"
};

const InfoIcon = React.forwardRef(
  ({ theme, size, className, ...props }, ref) => {return (<svg viewBox="0 0 24 24" {...props} className={cn(styles.icon, styles[theme], sizeStyles[size], className)} ref={ref}><path d="M12 21a9 9 0 100-17.999A9 9 0 0012 21zM10.546 7.563a1.5 1.5 0 112.893.79 1.5 1.5 0 01-2.893-.79zM10 12.494V11.5h3v6.495h-2V12.5h-1v-.006z" /></svg>)}
);

InfoIcon.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.oneOf(["light", "dark"]),
  size: PropTypes.oneOf(["xs", "s", "m", "l"])
};

InfoIcon.defaultProps = {
  size: "m"
};

export default InfoIcon;