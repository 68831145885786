import _slicedToArray from "@babel/runtime-corejs3/helpers/esm/slicedToArray";
import _sortInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/sort";
import _Array$from from "@babel/runtime-corejs3/core-js-stable/array/from";
import _valuesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/values";
import _Set from "@babel/runtime-corejs3/core-js-stable/set";
import { useRef, useState, useCallback, useEffect } from 'react';
import { htmlElementsComparator, getFirstItem, getLastItem, getPrevItem, getNextItem } from './navigation-utils.js';

function debounce(callback, timeout) {
  var id;
  return function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    clearTimeout(id);
    id = setTimeout(function () {
      return callback.apply(void 0, args);
    }, timeout);
    return id;
  };
}

function getSortedItems(itemsSet) {
  var _context;

  return _sortInstanceProperty(_context = _Array$from(_valuesInstanceProperty(itemsSet).call(itemsSet))).call(_context, htmlElementsComparator);
}

function useMenuKeyboardNavigation(_ref) {
  var enableKeyboardNavigation = _ref.enableKeyboardNavigation,
      activateFirstItem = _ref.activateFirstItem,
      returnFocusOnUnmount = _ref.returnFocusOnUnmount;
  var activeItemRef = useRef(null);

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      setDummyState = _useState2[1];

  var setActiveItemRef = useCallback(function (ref) {
    activeItemRef.current = ref;

    if (ref) {
      ref.focus();
    }

    setDummyState(function (t) {
      return !t;
    });
  }, [setDummyState]);
  var menuItemsSet = useRef(new _Set());
  useEffect(function () {
    if (!enableKeyboardNavigation || !activateFirstItem || !returnFocusOnUnmount) {
      return;
    }

    var lastFocusedEl = document.activeElement; // eslint-disable-next-line consistent-return

    return function () {
      if (lastFocusedEl) {
        lastFocusedEl.focus({
          preventScroll: true
        });
      }
    };
  }, []);
  var debouncedActivateFirstItem = debounce(function () {
    var items = getSortedItems(menuItemsSet.current);
    setActiveItemRef(getFirstItem(items));
  }, 50);
  var registerItem = useCallback(function (ref) {
    if (ref && !menuItemsSet.current.has(ref)) {
      menuItemsSet.current.add(ref);

      if (activateFirstItem) {
        debouncedActivateFirstItem();
      }
    }
  }, []);
  var unregisterItem = useCallback(function (ref) {
    menuItemsSet.current.delete(ref);
  }, []);

  var _useState3 = useState(true),
      _useState4 = _slicedToArray(_useState3, 2),
      isKeyboardNavigating = _useState4[0],
      setIsKeyboardNavigating = _useState4[1];

  var handleMouseMove = useCallback(function () {
    if (menuItemsSet.current.has(document.activeElement) && document.activeElement.blur) {
      document.activeElement.blur();
    }

    setActiveItemRef(null);
    setIsKeyboardNavigating(false);
  }, []); // eslint-disable-next-line consistent-return

  useEffect(function () {
    if (isKeyboardNavigating) {
      document.addEventListener('mousemove', handleMouseMove);
      return function () {
        return document.removeEventListener('mousemove', handleMouseMove);
      };
    }
  }, [handleMouseMove, isKeyboardNavigating]);
  useEffect(function () {
    if (!enableKeyboardNavigation) {
      return;
    }

    var handler = function handler(e) {
      setIsKeyboardNavigating(true);
      var items = getSortedItems(menuItemsSet.current);

      switch (e.key) {
        case 'ArrowDown':
          e.preventDefault();
          setActiveItemRef(getNextItem(items, activeItemRef.current));
          break;

        case 'ArrowUp':
          e.preventDefault();
          setActiveItemRef(getPrevItem(items, activeItemRef.current));
          break;

        case 'Home':
          e.preventDefault();
          setActiveItemRef(getFirstItem(items));
          break;

        case 'End':
          e.preventDefault();
          setActiveItemRef(getLastItem(items));
          break;
      }
    };

    document.addEventListener('keydown', handler, true); // eslint-disable-next-line consistent-return

    return function () {
      document.removeEventListener('keydown', handler, true);
    };
  }, [enableKeyboardNavigation]);
  return {
    activeItemRef: activeItemRef,
    registerItem: registerItem,
    unregisterItem: unregisterItem,
    isKeyboardNavigating: isKeyboardNavigating
  };
}

export { useMenuKeyboardNavigation };
