import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _defineProperty from "@babel/runtime-corejs3/helpers/esm/defineProperty";
import _slicedToArray from "@babel/runtime-corejs3/helpers/esm/slicedToArray";

function ownKeys(object, enumerableOnly) { var keys = _Object$keys(object); if (_Object$getOwnPropertySymbols) { var symbols = _Object$getOwnPropertySymbols(object); enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) { return _Object$getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : _Object$getOwnPropertyDescriptors ? Object.defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useState, useCallback } from 'react';
import { useResizeObserver } from './use-resize-observer.js';

function calculateIndicator(activeNodeRef, parentNode) {
  var childPos = activeNodeRef.getBoundingClientRect();
  var parentPos = parentNode.getBoundingClientRect();
  var left = "".concat(childPos.left - parentPos.left + parentNode.scrollLeft, "px");
  var width = "".concat(childPos.width, "px");
  return {
    left: left,
    width: width
  };
}

var useIndicator = function useIndicator(_ref) {
  var activeNodeRef = _ref.activeNodeRef,
      containerRef = _ref.containerRef,
      triggers = _ref.triggers;

  var _useState = useState({
    left: 0,
    width: 0
  }),
      _useState2 = _slicedToArray(_useState, 2),
      indicator = _useState2[0],
      setIndicator = _useState2[1];

  var _useState3 = useState(true),
      _useState4 = _slicedToArray(_useState3, 2),
      isSSR = _useState4[0],
      setIsSSR = _useState4[1]; // Catch the resize of the container which is happening when the fonts downloads for example
  // Also on value changing the resize observer will reattach itself, which triggers the function


  var onResize = useCallback(function () {
    if (!activeNodeRef.current || !containerRef.current) {
      return;
    }

    if (isSSR) {
      setIsSSR(false);
      setIndicator(_objectSpread(_objectSpread({}, calculateIndicator(activeNodeRef.current, containerRef.current)), {}, {
        transitionDuration: '0ms'
      }));
      return;
    }

    setIndicator(calculateIndicator(activeNodeRef.current, containerRef.current));
  }, triggers);
  useResizeObserver(containerRef, onResize);
  return indicator;
};

export { useIndicator };
