import _slicedToArray from "@babel/runtime-corejs3/helpers/esm/slicedToArray";
import { useState, useEffect } from 'react';

function isOverflown(element) {
  return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
}
/**
 * Hack for firefox and old safari.
 * Set "overflowY: scroll" for scrollable container when there is something to scroll.
 * Fixes situation when scrollbar takes space from the content which causes unexpected wrapping.
 * @see https://bugzilla.mozilla.org/show_bug.cgi?id=764076
 */


var useScrollableContainer = function useScrollableContainer() {
  var _useState = useState(null),
      _useState2 = _slicedToArray(_useState, 2),
      contentEl = _useState2[0],
      setContentEl = _useState2[1];

  var _useState3 = useState(false),
      _useState4 = _slicedToArray(_useState3, 2),
      isScrollable = _useState4[0],
      setIsScrollable = _useState4[1];

  useEffect(function () {
    if (contentEl && typeof ResizeObserver !== 'undefined') {
      var containerEl = contentEl.parentElement;
      var resizeObserver = new ResizeObserver(function (entries) {
        // @see https://stackoverflow.com/a/58701523
        window.requestAnimationFrame(function () {
          if (!Array.isArray(entries) || !entries.length) {
            return;
          }

          setIsScrollable(isOverflown(containerEl));
        });
      });
      resizeObserver.observe(contentEl);
      return function () {
        resizeObserver.disconnect();
      };
    } // eslint-disable-next-line @typescript-eslint/no-empty-function


    return function () {};
  }, [contentEl]);
  var scrollableStyles = {
    overflowY: isScrollable ? 'scroll' : 'auto'
  };
  return {
    setContentEl: setContentEl,
    scrollableStyles: scrollableStyles
  };
};

export { useScrollableContainer };
