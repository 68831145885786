var styles = {
  "main": "_main_xxajeh_17",
  "rs-reset-letter-spacing": "_rs-reset-letter-spacing_xxajeh_1",
  "busy": "_busy_xxajeh_49",
  "busyOverlay": "_busyOverlay_xxajeh_53",
  "light": "_light_xxajeh_59",
  "disabled": "_disabled_xxajeh_61",
  "dark": "_dark_xxajeh_62",
  "alignIconLeft": "_alignIconLeft_xxajeh_77",
  "alignIconRight": "_alignIconRight_xxajeh_81",
  "sizeXs": "_sizeXs_xxajeh_85",
  "sizeS": "_sizeS_xxajeh_92",
  "sizeM": "_sizeM_xxajeh_99",
  "sizeL": "_sizeL_xxajeh_106",
  "allowMultiline": "_allowMultiline_xxajeh_114",
  "multiline": "_multiline_xxajeh_118",
  "withoutText": "_withoutText_xxajeh_138",
  "withIcon": "_withIcon_xxajeh_144",
  "busyIcon": "_busyIcon_xxajeh_150",
  "flatRight": "_flatRight_xxajeh_158",
  "flatLeft": "_flatLeft_xxajeh_163",
  "modeClassic": "_modeClassic_xxajeh_168",
  "modeRock": "_modeRock_xxajeh_241",
  "modeTransparent": "_modeTransparent_xxajeh_319",
  "modeOutline": "_modeOutline_xxajeh_389",
  "modeClear": "_modeClear_xxajeh_478",
  "highlighted": "_highlighted_xxajeh_513",
  "shiftedLeft": "_shiftedLeft_xxajeh_564",
  "shiftedRight": "_shiftedRight_xxajeh_565",
  "shiftedTop": "_shiftedTop_xxajeh_566",
  "shiftedBottom": "_shiftedBottom_xxajeh_567",
  "icon": "_icon_xxajeh_569"
};
export { styles as default };
