import { detectOverflow } from '@popperjs/core';

var maxSize = {
  name: 'maxSize',
  enabled: true,
  phase: 'main',
  requiresIfExists: ['offset', 'preventOverflow', 'flip'],
  fn: function fn(_ref) {
    var state = _ref.state,
        name = _ref.name,
        options = _ref.options;
    var overflow = detectOverflow(state, options);

    var _ref2 = state.modifiersData.preventOverflow || {
      x: 0,
      y: 0
    },
        x = _ref2.x,
        y = _ref2.y;

    var _state$rects$popper = state.rects.popper,
        width = _state$rects$popper.width,
        height = _state$rects$popper.height;

    var _state$placement$spli = state.placement.split('-'),
        basePlacement = _state$placement$spli[0];

    var widthProp = basePlacement === 'left' ? 'left' : 'right';
    var heightProp = basePlacement === 'top' ? 'top' : 'bottom';
    state.modifiersData[name] = {
      width: width - overflow[widthProp] - x,
      height: height - overflow[heightProp] - y
    };
  }
};

export default maxSize;
