import _slicedToArray from "@babel/runtime-corejs3/helpers/esm/slicedToArray";
import { useState, useEffect } from 'react';
var TOOLTIP_STATES;

(function (TOOLTIP_STATES) {
  TOOLTIP_STATES["OPEN"] = "OPEN";
  TOOLTIP_STATES["CLOSING"] = "CLOSING";
  TOOLTIP_STATES["CLOSED"] = "CLOSED";
})(TOOLTIP_STATES || (TOOLTIP_STATES = {}));

function useTooltipState(isOpen) {
  var transitionDuration = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;

  var _useState = useState(TOOLTIP_STATES.CLOSED),
      _useState2 = _slicedToArray(_useState, 2),
      state = _useState2[0],
      setState = _useState2[1];

  useEffect(function () {
    if (isOpen) {
      // set tooltip state to OPEN when it should be open, but the current state is closing or already closed
      setState(function (curState) {
        if (curState === TOOLTIP_STATES.CLOSING || curState === TOOLTIP_STATES.CLOSED) {
          return TOOLTIP_STATES.OPEN;
        } else {
          return curState;
        }
      });
    }

    if (!isOpen) {
      // if tooltip should not be open, but actually open, start the closing process
      setState(function (curState) {
        if (curState === TOOLTIP_STATES.OPEN) {
          return TOOLTIP_STATES.CLOSING;
        }

        return curState;
      }); // by the time the transition is finished, set state from closing to closed
      // if by any means at that moment state is not closing (but open or already closed), do nothing

      var timeoutId = setTimeout(function () {
        setState(function (curState) {
          if (curState === TOOLTIP_STATES.CLOSING) {
            return TOOLTIP_STATES.CLOSED;
          }

          return curState;
        });
      }, transitionDuration);
      return function () {
        clearTimeout(timeoutId);
      };
    } // eslint-disable-next-line @typescript-eslint/no-empty-function


    return function () {};
  }, [transitionDuration, isOpen]);
  return state;
}

export { TOOLTIP_STATES, useTooltipState };
