
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './index.p.css';

const sizeStyles = {
  "xs": styles.sizeXS,"s": styles.sizeS,"m": styles.sizeM,"l": styles.sizeL
}

export const Size = {
  "xs": "xs","s": "s","m": "m","l": "l"
};

const LoadingIcon = React.forwardRef(
  ({ theme, size, className, ...props }, ref) => {return (<svg viewBox="0 0 24 24" {...props} className={cn(styles.icon, styles[theme], sizeStyles[size], className)} ref={ref}><g style={{
    animation: "rs-icon-rotate 1s linear infinite",
    transformOrigin: "center center"
  }}><path opacity={0.2} d="M12 2c5.523 0 10 4.477 10 10h-2a8 8 0 00-8-8V2z" /><path d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10h-2a8 8 0 11-8-8V2z" /></g></svg>)}
);

LoadingIcon.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.oneOf(["light", "dark"]),
  size: PropTypes.oneOf(["xs", "s", "m", "l"])
};

LoadingIcon.defaultProps = {
  size: "m"
};

export default LoadingIcon;