import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _defineProperty from "@babel/runtime-corejs3/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = _Object$keys(object); if (_Object$getOwnPropertySymbols) { var symbols = _Object$getOwnPropertySymbols(object); enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) { return _Object$getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : _Object$getOwnPropertyDescriptors ? Object.defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key)); }); } return target; }

import { RS_COLOR_WHITE, RS_COLOR_PRIMARY_LIGHT_THEME, RS_COLOR_BLACK, RS_COLOR_BLACK_T70, RS_COLOR_BLACK_T10, RS_COLOR_PRIMARY_DARK_THEME, RS_COLOR_WHITE_T70, RS_COLOR_WHITE_T10 } from '@rescui/colors';
var presetsLight = {
  /** Primary background */
  classicLight: {
    color: RS_COLOR_WHITE,
    backgroundColor: "var(--rs-color-primary-light-theme, ".concat(RS_COLOR_PRIMARY_LIGHT_THEME, ")")
  },

  /** Contrast background */
  rockLight: {
    color: RS_COLOR_WHITE,
    backgroundColor: RS_COLOR_BLACK
  },

  /** Grey background */
  filledLight: {
    color: RS_COLOR_BLACK_T70,
    backgroundColor: RS_COLOR_BLACK_T10
  },

  /** Transparent with border */
  outlineLight: {
    color: RS_COLOR_BLACK,
    borderColor: RS_COLOR_BLACK
  }
};
var presetsDark = {
  /** Primary background */
  classicDark: {
    color: RS_COLOR_WHITE,
    backgroundColor: "var(--rs-color-primary-dark-theme, ".concat(RS_COLOR_PRIMARY_DARK_THEME, ")")
  },

  /** Contrast background */
  rockDark: {
    color: RS_COLOR_BLACK,
    backgroundColor: RS_COLOR_WHITE
  },

  /** Grey background */
  filledDark: {
    color: RS_COLOR_WHITE_T70,
    backgroundColor: RS_COLOR_WHITE_T10
  },

  /** Transparent with border */
  outlineDark: {
    color: RS_COLOR_WHITE,
    borderColor: RS_COLOR_WHITE
  }
};

var presets = _objectSpread(_objectSpread({}, presetsLight), presetsDark);

export { presets };
